import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginTop: '20px',
    position: 'relative',
  },
  statLabel: {
    marginBottom: theme.spacing(1),
  },
  statValue: {
    color: theme.palette.primary.main,
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  statValueSecondary: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  statValueNormal: {
    color: theme.palette.success.main,
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  statValueWarning: {
    color: theme.palette.error.main,
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  messageText: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  successMessage: {
    color: theme.palette.success.main,
  },
  warningMessage: {
    color: theme.palette.error.main,
  },
  helpIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 1,
  },
  popover: {
    padding: theme.spacing(3),
    maxWidth: 600,
  },
  popoverTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  popoverText: {
    whiteSpace: 'pre-line',
  },
}));

export default useStyles;
