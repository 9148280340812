import axios from '../../utils/axios';
import { BAD_REQUEST, INTERNAL_SERVER_ERROR, OK } from '../../constants/http';

const postCreateDeveloper = async (
  {
    clockifyApiKey,
    contractType,
    jobTitle,
    github,
    linkedin,
    email,
    costPerHour,
    contractHours,
    contractStartDate,
  },
) => {
  try {
    const response = await axios.post('/api/developer/create', {
      clockifyApiKey,
      contractType,
      jobTitle,
      github,
      linkedin,
      email,
      costPerHour,
      contractHours,
      contractStartDate,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getDevelopersPerPage = async (page, limit, searchValue, monthAvailable) => {
  try {
    const { data } = await axios.get(`/api/developers?page=${page}&limit=${limit}&searchValue=${searchValue}&monthAvailable=${monthAvailable}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getDeveloperById = async (id) => {
  try {
    const { data } = await axios.get(`/api/developer/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putDeveloperById = async (
  {
    id,
    contractType,
    clockifyApiKey,
    jobTitle,
    github,
    linkedin,
    costPerHour,
    contractHours,
    contractStartDate,
    minDefaultHours,
    maxDefaultHours,
    maxConcurrentProjects,
  },
) => {
  try {
    const response = await axios.put('/api/developer/edit', {
      id,
      contractType,
      clockifyApiKey,
      jobTitle,
      github,
      linkedin,
      costPerHour,
      contractHours,
      contractStartDate,
      minDefaultHours,
      maxDefaultHours,
      maxConcurrentProjects,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteDeveloper = async (id) => {
  try {
    const { data } = await axios.delete(`/api/developer/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAllDevelopers = async () => {
  try {
    const { data } = await axios.get('/api/all/developers');

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getDeveloperProjectsPerMonth = async ({ id, month }) => {
  try {
    const { data } = await axios.get(`/api/developer-projects/${id}?month=${month}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAllDevelopersGraphic = async ({ month }) => {
  try {
    const { data } = await axios.get(`/api/developers/all-developers-graphic?month=${month}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getDeveloperGraphic = async ({ id, month }) => {
  try {
    const { data } = await axios.get(`/api/developer/developer-graphic/${id}?month=${month}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getDevelopersPerPageWithVacationDays = async (limit, page, searchValue) => {
  try {
    const { data } = await axios.get(`/api/developers-with-vacations?limit=${limit}&page=${page}&searchValue=${searchValue}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getDeveloperRemainingHours = async (id) => {
  try {
    const { data } = await axios.get(`/api/developer/remaining-hours/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data: data.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default {
  postCreateDeveloper,
  getDevelopersPerPage,
  getDeveloperById,
  putDeveloperById,
  deleteDeveloper,
  getAllDevelopers,
  getDeveloperProjectsPerMonth,
  getAllDevelopersGraphic,
  getDeveloperGraphic,
  getDevelopersPerPageWithVacationDays,
  getDeveloperRemainingHours,
};
