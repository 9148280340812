import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Grid,
  Typography,
  IconButton,
  Popover,
} from '@material-ui/core';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import developerRequest from '../../requests/api/developer';
import useStyles from './styles/RemainingHours.styles';
import MESSAGES from './constants/remainingHours.constants';

function RemainingHours({ developerId }) {
  const classes = useStyles();
  const [workHours, setWorkHours] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleHelpClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHelpClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchWorkHours = async () => {
      const response = await developerRequest.getDeveloperRemainingHours(developerId);
      if (response.success) {
        setWorkHours(response.data);
      } else {
        setWorkHours(null);
      }
    };

    fetchWorkHours();
  }, [developerId]);

  const getHoursStyle = () => {
    if (!workHours.targetHours) return classes.statValue;

    const dailyContractHours = workHours.targetHours / 20;
    const isOverHours = dailyContractHours < workHours.targetHoursByDay;

    return isOverHours ? classes.statValueWarning : classes.statValueNormal;
  };

  const getMessage = () => {
    if (!workHours.targetHours) return null;

    if (workHours.remainingHours === 0) {
      return (
        <Typography
          className={`${classes.messageText} ${classes.successMessage}`}
        >
          {MESSAGES.COMPLETED}
        </Typography>
      );
    }

    const dailyContractHours = workHours.targetHours / 20;
    const isOverHours = dailyContractHours < workHours.targetHoursByDay;

    return (
      <Typography
        className={`${classes.messageText} ${isOverHours ? classes.warningMessage : classes.successMessage}`}
      >
        {isOverHours ? MESSAGES.WARNING : MESSAGES.SUCCESS}
      </Typography>
    );
  };

  if (!workHours) {
    return null;
  }

  return (
    <Card className={classes.card}>
      <IconButton
        className={classes.helpIcon}
        onClick={handleHelpClick}
        size="small"
      >
        <HelpOutlineIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleHelpClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box className={classes.popover}>
          <Typography variant="h5" className={classes.popoverTitle}>
            {MESSAGES.POPOVER_TITLE}
          </Typography>
          <Typography
            className={classes.popoverText}
            dangerouslySetInnerHTML={{
              __html: MESSAGES.POPOVER_TEXT,
            }}
          />
        </Box>
      </Popover>

      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Box textAlign="center">
            <Typography
              color="textSecondary"
              variant="h6"
              className={classes.statLabel}
            >
              Horas Registradas en el Mes
            </Typography>
            <Typography className={classes.statValue}>
              {workHours.hoursWorked}
              <Typography component="span" className={classes.statValueSecondary}>
                {' / '}{workHours.targetHours}
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box textAlign="center">
            <Typography
              color="textSecondary"
              variant="h6"
              className={classes.statLabel}
            >
              Horas Por Registrar en el Mes
            </Typography>
            <Typography className={classes.statValue}>
              {workHours.remainingHours}
              <Typography component="span" className={classes.statValueSecondary}>
                {' / '}{workHours.targetHours}
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box textAlign="center">
            <Typography
              color="textSecondary"
              variant="h6"
              className={classes.statLabel}
            >
              Días Hábiles Restantes
            </Typography>
            <Typography className={classes.statValue}>
              {workHours.developerRemainingDays}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box textAlign="center">
            <Typography
              color="textSecondary"
              variant="h6"
              className={classes.statLabel}
            >
              Horas a Registrar por Día
            </Typography>
            <Typography className={getHoursStyle()}>
              {workHours.targetHoursByDayFormatted}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {getMessage()}
    </Card>
  );
}

RemainingHours.propTypes = {
  developerId: PropTypes.number.isRequired,
};

export default RemainingHours;
